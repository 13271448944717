import React from 'react'

import { Box, Stack, Typography } from '@mui/material'

import DoDisturbIcon from '@mui/icons-material/DoDisturb';




const StoreBlockPrompt = () => {
  return (
    <div style={{
      position: "relative",
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <Box>
        <Stack>
          <Stack direction={"row"} spacing={1} justifyContent={"center"}>
            <div>
              <DoDisturbIcon sx={{ fill: "red" }} />
            </div>
            <Typography>Your store is currently blocked</Typography>
          </Stack>
          <Typography>Please contact your administrator</Typography>
          <Box
            height={10}
          />
          <Stack direction={"row"} justifyContent={"end"}>
            <Typography fontSize={10}>A friendly message from the other side</Typography>
          </Stack>
        </Stack>
      </Box>
    </div>
  )
}

export default StoreBlockPrompt