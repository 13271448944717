import React, { useEffect, useContext, useState } from "react"

import HomeMade from "../assets/svg/logos/homemade.svg"
import Express from "../assets/svg/logos/spark-express.svg"
import FoodBasket from "../assets/svg/logos/food-basket.svg"
import Sparkle247 from "../assets/svg/logos/sparkle-24-7.svg"
import Fresh from "../assets/svg/logos/fresh.svg"
import Electronics from "../assets/svg/logos/electronics.svg"

import Bills from "../assets/svg/logos/bills.svg"
import Load from "../assets/svg/logos/load.svg"
import Voucher from "../assets/svg/logos/voucher.svg"

import SparkOnAir from "../components/SparkOnAir"

import Spark from "../assets/svg/Mascot_Stand_Black_Stroke.svg"
import bayadDirectLogo from "../assets/svg/bayaddirect.jpg"
import sparkleAirBanner from "../assets/images/spark_on_air_landscape.png"
import HelpIcon from "@mui/icons-material/Help"
import TipsModal from "./TipsModal"

import PoweredByPldt from "../components/PoweredByPldt"

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Grid from "@material-ui/core/Grid"
import { Link, navigate } from "gatsby"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import SecureLS from "secure-ls"

import "../assets/css/masterindexpage.css"
import "../assets/scss/IndexPage.scss"
import "../assets/css/layout.css"

import Promotions from "./Promotions/Promotions"
import InputBase from "@material-ui/core/InputBase"

import VortexBottomGradient from "./BottomGradient"

import NeedHelp from "./NeedHelp"

import { GlobalStateSearchResult } from "./Homemade/SearchPage/state/SearchPageState"

import UAParser from "ua-parser-js"

import "../assets/css/addToHome.css"
// import { searchProducts } from '../api/public/search'

import { LoginState, StoreStatus, UserStatus } from "./globalstates"
import "animate.css"
import { Button, Stack, Typography } from "@mui/material"
import VortexServices from "./Vortex/components/VortexServices"
import FacebookDialog from "./Dialogs/FacebookDialog"
import InstallOptionDialog from "./Dialogs/InstallOptionDialog"
import { Box } from "@mui/system"
import { updateVortexByRefId } from "../api/public/vortex/transaction_db"
import Bottom from "./Homemade/BottomNavigator"
import LoginPage from "./LoginPage"
// import { sentryCatchException } from "../services/sentry/sentry"

// import TestAdd from "./Homemade/ShopPage/testAdd"
import { API } from "../api/api-config"
import useLoggedUser from "../custom-hooks/useLoggedUser"
import { getUserById } from "../api/public/user"
import BlockPrompt from "./Prompts/BlockPrompt"
import StoreBlockPrompt from "../components/Prompts/StoreBlockPrompt"


const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginLeft: "0em",
    width: "100%",
  },
  control: {
    padding: theme.spacing(2),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    background: "white",
  },
  font: {
    fontFamily: "visby",
    position: "absolute",
    fontWeight: "bold",
    paddingTop: "2.6rem",
    fontSize: "13px",
    paddingLeft: ".1rem",
  },
}))

let parser = new UAParser()
let browser = parser.getBrowser()

const MasterIndexPage = () => {
  const ls = new SecureLS({ encodingType: "aes" })
  const classes = useStyles()

  //   const [userAgent, setUserAgent] = useState('')
  //   const [isAndroid, setIsAndroid] = useState(false)
  //   const [isApple, setIsApple] = useState(false)
  //   const [isWindows, setIsWindows] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [customerList, setCustomerList] = useState([])

  const [searchResults, setsearchResults] = useContext(GlobalStateSearchResult)

  const [isLoggin, setisLoggin] = useContext(LoginState)

  const [userStatus, setUserStatus] = useContext(UserStatus)

  const [storeStatus, setstoreStatus] = useContext(StoreStatus)

  const [cashier, setcashier] = useState(false)

  const [open, setOpen] = useState(false)

  const [customerUser, setCustomerUser] = useState(ls.get('currentCustomer'))

  const [messengerOpen, setMessengerOpen] = useState(false)

  const [value, setValue] = useState(null);

  const [openCustomer, toggleOpenCustomer] = useState(false);

  const { getUser, isLoggedIn } = useLoggedUser()

  const getCurrentCashierStatus = async () => {
    try {
      let { userId } = getUser()

      let result = await getUserById(userId)

      setUserStatus(result?.status)

      setstoreStatus(result?.store?.status)

    } catch (error) {
      alert(error)
    }
  }

  const handleClose = () => {
    setDialogValue({
      name: '',
      email: '',
      phone: '',
    });

    toggleOpenCustomer(false);
  };

  const [dialogValue, setDialogValue] = useState({
    name: '',
    email: '',
    phone: '',
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    let response = await fetch(`${API}/customer/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dialogValue),
    })
      .then((response) => {
        
        return response
      })
      .catch((err) => {

        return err
      })

    let jsonData = await response.json()
    console.log(jsonData)

    if(jsonData.error) {
      alert(jsonData.error)
    } else {
     
      setCustomerUser(jsonData.data);
      ls.set('currentCustomer', jsonData.data)
  
      setValue({
        name: dialogValue.name,
        email: dialogValue.email,
        phone: dialogValue.phone,
      });
  
      handleClose();
    }

   
  };

  useEffect(() => {
    let isLogin = isLoggedIn()

    let user = getUser()

    if (isLogin) {
      setcashier(user)
      setisLoggin(isLogin)
      getCurrentCashierStatus()
    }

  }, [])

  let browserName = browser.name
  //

  useEffect(() => {
    if (browserName.includes("Facebook")) {
      window.location.replace(`${window.location.href}/#fromMessenger`)
      setOpen(true)
    }
  }, [])

  let url = window.location.href

  useEffect(async () => {
    let response = await fetch(`${API}/customer/list/all`, {
      method: "GET",

      headers: {
        Accept: "application/json",
      },
    })

    let jsonData = await response.json()
    setCustomerList(jsonData)
  }, [value])

  useEffect(() => {
    if (url.includes("#fromMessenger")) {
      setMessengerOpen(true)
    }
  }, [])




  return (
    <>
      {!isLoggin && <LoginPage />}
      {storeStatus === 0 &&
        <StoreBlockPrompt />
      }
      {userStatus === 0 && (
        <BlockPrompt />
      )
      }
      {isLoggin && !open && userStatus === 1 && storeStatus === 1 ? (
        <div className="page ">
          <Container component="main" maxWidth="xs" className="page">
            <TipsModal />
            {/* <NeedHelp /> */}
            {messengerOpen ? <InstallOptionDialog /> : ''}
            <div className="heading">
              <div className="heading-bg">
                <div className="heading-greetings">
                  <div>
                    {/* <img
                      className="heading-logo" //animate__bounceIn
                      src={bayadDirectLogo}
                      alt="bayadDirectLogo"
                    /> */}

                    <Box sx={{ height: "10em" }} />
                  </div>
                  {/* <div>
                    <div className="heading-text animate__bounceIn">
                      <h1 className="heading-header">Sparkling Day, Juan!</h1>
                      What would you like to do for today?
                    </div>
                  </div> */}
                </div>
                <div className="heading-search-container">
                  {/* <div className="heading-search-shape">
                    <InputBase
                      disabled={true}
                      style={{
                        width: "100%",
                        fontFamily: "montserrat",
                        fontSize: "1em",
                        fontWeight: "500",
                        color: "#6b6b6b",
                        paddingLeft: "0.3em",
                        zIndex: 999,
                      }}
                      placeholder="Search for products here"
                      onClick={() => {
                        navigate("/search")
                      }}
                      // onKeyPress={(e) => {
                      //   // alert(e.key)

                      //   if (e.key === 'Enter') {
                      //     navigate('/search')
                      //   }
                      // }}
                    />
                  </div> */}
                </div>
              </div>
            </div>
            {/* Services Tiles */}
            {/* <div
              className="menu"
              style={{ overflowX: "hidden", overflowY: "hidden" }}
            >
              <Grid container spacing={4} className={classes.root}>
                <Grid item xs={4}>
                  <Link to="/food">
                    <img src={HomeMade} height="100px" alt="Home" />
                  </Link>
                  <div className="menu--text">Homemade</div>
                </Grid>
                <Grid item xs={4}>
                  <Link to="/express">
                    <img src={Express} height="100px" alt="Express" />
                  </Link>
                  <div className="menu--text">Express Delivery</div>
                </Grid>
                <Grid item xs={4} style={{ opacity: "0.5" }}>
                  <Link>
                    
                    <img src={FoodBasket} height="100px" alt="Express" />
                  </Link>
                  <div className="menu--text">Food Basket</div>
                </Grid>
              </Grid>
              <Grid container spacing={4} className={classes.root}>
                <Grid item xs={4} style={{ opacity: "0.5" }}>
                  <Link>
                    <img src={Sparkle247} height="100px" alt="Sparkle 24/7" />
                  </Link>
                  <div className="menu--text">Sparkle 24/7</div>
                </Grid>
                <Grid item xs={4} style={{ opacity: "0.5" }}>
                  <Link>
                    <img src={Fresh} height="100px" alt="Fresh" />
                  </Link>
                  <div className="menu--text">Fresh</div>
                </Grid>
                <Grid item xs={4} style={{ opacity: "0.5" }}>
                  <Link>
                    <img src={Electronics} height="100px" alt="Electronics" />
                  </Link>
                  <div className="menu--text">Electronics</div>
                </Grid>
              </Grid>
            </div> */}
            {/* Promotion banners */}
            {/* <PoweredByPldt /> */}

            <Stack m={3} direction={"row"} justifyContent={"right"}>
              <Button
                variant="outlined"
                onClick={() => {
                  navigate("/account")
                }}
              >
                Cashier: {cashier.name}
              </Button>
            </Stack>

            {!customerUser &&
              <>
                {/* <Stack m={3} direction={"column"} justifyContent={"right"}  sx={{marginTop: "5em"}}>
                  <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    color="primary"
                      onClick={() => {
                        // navigate("/account")
                      }}
                    >
                      NEW CUSTOMER
                    </Button>
                    
              </Stack> */}

                <Stack m={3} direction={"row"} justifyContent={"center"}  >
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={customerList} //.map((option) => option)
                    autoHighlight
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    getOptionLabel={(option) => {
                      // e.g value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.name;
                    }}
                    renderInput={(params) => <TextField {...params} label="Customer Name/Phone/Email" />}
                    renderOption={(props, option) => <li {...props}>{option.name} {option.email} {option.phone}</li>}
                    sx={{ width: 300 }}
                    // onChange={(e)=> {
                    //   setCustomerUser(e.target.innerText)
                    //   console.log(e.target.innerText)
                    // }}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        // timeout to avoid instant validation of the dialog's form.
                        setTimeout(() => {
                          toggleOpenCustomer(true);
                          setDialogValue({
                            name: newValue,
                            phone: '',
                            email: '',
                          });
                        });
                      } else if (newValue && newValue.inputValue) {
                        toggleOpenCustomer(true);
                        setDialogValue({
                          name: newValue.inputValue,
                          phone: '',
                          email: '',
                        });
                      } else {
                        setCustomerUser(newValue);
                        ls.set('currentCustomer', newValue)
                        // setValue(newValue);
                      }

                    }}

                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          name: `Add "${params.inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                  />

                  <Dialog open={openCustomer} onClose={handleClose}>
                    <form onSubmit={handleSubmit}>
                      <DialogTitle>Add a new Customer</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          Can't see the customer in our list? Please, add it!
                        </DialogContentText>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          value={dialogValue.name}
                          onChange={(event) =>
                            setDialogValue({
                              ...dialogValue,
                              name: event.target.value,
                            })
                          }
                          label="name"
                          type="text"
                          variant="standard"
                        />
                        <br />
                        <TextField
                          margin="dense"
                          id="name"
                          value={dialogValue.email}
                          onChange={(event) =>
                            setDialogValue({
                              ...dialogValue,
                              email: event.target.value,
                            })
                          }
                          label="email"
                          type="text"
                          variant="standard"
                        />
                        <br />
                        <TextField
                          margin="dense"
                          id="name"
                          value={dialogValue.phone}
                          onChange={(event) =>
                            setDialogValue({
                              ...dialogValue,
                              phone: event.target.value,
                            })
                          }
                          label="phone"
                          type="text"
                          variant="standard"
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                      </DialogActions>
                    </form>
                  </Dialog>
                  {/* <Button
                    variant="contained"
                    size="small"
                    sx={{width: 50, height: 50, marginTop: "1.5em", marginLeft: 3}}
                    
                      onClick={() => {
                        // navigate("/account")
                      }}
                    >
                      QR
                    </Button> */}
                </Stack>
              </>
            }
            <Box sx={{ height: "3em" }} />
            {/* <Button
              onClick={async () => {
                let updateObj = {
                  paymongoRefundResourceID: "TEST",
                }

                return await updateVortexByRefId({
                  refId: "TEST",
                  data: updateObj,
                })
              }}
            >
              Throw Error
            </Button> */}
            {customerUser &&
              <>
                <Stack m={3} direction={"row"} justifyContent={"center"}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      // navigate("/account")
                      setCustomerUser('')
                      ls.set('currentCustomer', '')
                    }}
                  >
                    Customer: {customerUser.name} {customerUser.email} {customerUser.phone}
                  </Button>

                </Stack>

                <Grid container spacing={4} className={classes.root}>
                  {/* style={{ opacity: "0.5" }} */}
                  <Grid item xs={4}>
                    <Link to="/vortexbillspayment">
                      <img src={Bills} height="100px" alt="Home" />
                    </Link>
                    <div className="menu--text">Bills</div>
                  </Grid>
                  <Grid item xs={4}>
                    {/* <Link > */}
                    {/* to="/vortextopup/electronic_load" */}
                    <Link role={"vortex-topup"} to="/vortextopup/electronic_load">
                      <img src={Load} height="100px" alt="Express" />
                    </Link>
                    <div className="menu--text">Load</div>
                  </Grid>
                  <Grid item xs={4}>
                    <Link to="/vortexvoucher">
                      <img src={Voucher} height="100px" alt="Express" />
                    </Link>
                    <div className="menu--text">Vouchers</div>
                  </Grid>
                </Grid>
                <Stack m={3} direction={"row"} justifyContent={"center"}>
                  <Button
                    onClick={() => {
                      navigate("/vortextransactions")
                    }}
                  >
                    View transactions
                  </Button>
                </Stack>

              </>}

            {/* <Button
          onClick={() => {
            showPaymentForm()
          }}
        >
          PAYMONGO TEST
        </Button> */}
            {/* <PaymongoPaymentDialog calculatedTotalAmountToPay={1000} /> */}

            {/* <div
              style={{
                fontFamily: "montserrat",
                marginTop: "1em",
                marginBottom: "1em",
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              Offers you may like
            </div>
            <SparkOnAir /> */}
            <div
              className="PromotionBanners"
              style={{ overflowX: "hidden", height: "300px" }}
            >
              {/* <Promotions /> */}
            </div>
            <Bottom
            // value={homeMadeBottomNavigationIndex}
            // onChange={(event, newValue) => {
            //   sethomeMadeBottomNavigationIndex(newValue)
            // }}
            />
            <VortexBottomGradient />
            <Box sx={{ height: "10em" }} />
          </Container>
        </div>
      ) : (
        // <FacebookDialog permitted={open} />
        <></>
      )}
    </>
  )
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  {
    title: 'Jamal Dylan Begtang Hontiveros'
    , year: 1994
  },
  {
    title: 'Jeffery Russell Limcuando Lavares'
    , year: 1972
  },
  {
    title: 'Ray Elmer Meneses Villosillo'
    , year: 1974
  },
  {
    title: 'Devon Eron Sanqui Recto'
    , year: 2008
  },
  {
    title: 'Guillelmina Jagger Guimatao Santiago'
    , year: 1957
  },
  {
    title: 'Reed Mauro Lacson Ilagan'
    , year: 1993
  },
  {
    title: 'Deshaun Ulises Siongco Osorio'
    , year: 1994
  },
  {
    title: 'Jude Dantae Yuson Balbutin',
    year: 2003,
  },
  {
    title: 'Bartoli Christian Gosiengfiao Palad'
    , year: 1966
  },
  {
    title: 'Jeremias Alarico Chincuanco Mallari'
    , year: 1999
  },
  {
    title: 'Tyson Leon Simangan López',
    year: 2001,
  },
  {
    title: 'Mohamed Douglas Usman Divinagracia',
    year: 1980,
  },
  {
    title: 'Zayne Estefan Baang Galura'
    , year: 1994
  },
  {
    title: 'Joey Ramone Olan Fontanilla'
    , year: 2010
  },
  {
    title: 'Jadyn Moses Tiolengco Pamplona',
    year: 2002,
  },
  {
    title: 'Toby Agustin Abu Balagtas'
    , year: 1975
  },
  {
    title: 'Norman Ezequiel Mangurun Jiménez'
    , year: 1990
  },
  {
    title: 'Reagan Galeno Masipag Sicat'
    , year: 1999
  },
  {
    title: 'Samuel Joshua Capil Luz'
    , year: 1954
  },
  {
    title: 'Luciano Aurelio Banog Kalaw',
    year: 1977,
  },
  {
    title: 'Alejandro Garcia Leyco Daclan'
    , year: 2002
  },
  {
    title: 'Terrance Josiah Tubongbanua Etrone'
    , year: 1995
  },
  {
    title: 'Wayne Bembe Mangayao Herrera'
    , year: 1991
  },
  {
    title: 'Tristian Ronaldo Dacudag Francisco'
    , year: 1946
  },
  {
    title: 'Rhett Justin Junco Diwata'
    , year: 1997
  },
  {
    title: 'Malakai Rogelio Baal Carandang'
    , year: 1995
  },
  {
    title: 'Conner Solomon Buat Puno'
    , year: 1994
  },
  {
    title: 'Natanael Nehemiah Carim Bonachita'
    , year: 2001
  },
  {
    title: 'Tristan Abel Tanhehco Reyes'
    , year: 1998
  },
  {
    title: 'Estelita Jena Rahman Barerra'
    , year: 1968
  },
  {
    title: 'Anne Delaney Diongon Guerra'
    , year: 1998
  },
  {
    title: 'Arely Halle Habibi Atayde'
    , year: 2014
  },
  {
    title: 'Kaia Antonia Tuazon Rivera'
    , year: 1942
  },
  {
    title: 'Amari Celina Otogan Mesías'
    , year: 1931
  },
  {
    title: 'Kaylie Asianah Rice Velasco'
    , year: 1960
  },
  {
    title: 'Xeveria Simona Sese Lindo'
    , year: 1999
  },
  {
    title: 'Dulce Brenna Tawan Diwata'
    , year: 2011
  },
  {
    title: 'Jamya Laila Lacsina Manaloto'
    , year: 1936
  },
  {
    title: 'Caitlyn Lesly Diongon Sullano'
    , year: 1981
  },
  {
    title: 'Ericka Darby Dalrymple Villarino'
    , year: 1954
  },
  {
    title: 'Aubree Tejana Kumulitog Mallari'
    , year: 2002
  },
  {
    title: 'Dorotea Karlee Hopkins Guevarra'
    , year: 2006
  },
  {
    title: 'Nyah Sadie Otogan Escribano'
    , year: 1991
  },
  {
    title: 'Yadira Kaitlynn Sarip Lacsamana'
    , year: 1985
  },
  {
    title: 'Jolie Calida Alonto Cerezo'
    , year: 2014
  },
  {
    title: 'Deja Elise Dilangalen Magtibay'
    , year: 2000
  },
  {
    title: 'Candie Gaspara Disomimba Capillo'
    , year: 2000
  },
  {
    title: 'Celina Soledada Guro Bristol'
    , year: 2006
  },
  {
    title: 'Carmela Laney Nacu Belisario'
    , year: 1994
  },
  {
    title: 'Valeria Thalia Chanco Mendoza'
    , year: 1979
  },
  {
    title: 'Brittney Judith Manansala Carrasco'
    , year: 1979
  },
  {
    title: 'Valerie Katharine Subrabas Portugal'
    , year: 1950
  },
  {
    title: 'Julia Clodovea Mangao Malicdem',
    year: 1964,
  },
  {
    title: 'Dorinda Corinne Quiogue Cuevas'
    , year: 1940
  },
  {
    title: 'Isabella Evita Umpa Caparas'
    , year: 1988
  },
  {
    title: 'Lolitta Karly Magpantay Rosario'
    , year: 2006
  },
  {
    title: 'Katia Frescura Chua Roces'
    , year: 1988
  },
  {
    title: 'Anjali Ryleigh Yongque Custodio'
    , year: 1957
  },
  {
    title: 'Ciara Maritza Syquia Tugonon'
    , year: 2012
  },
  {
    title: 'Hallie Larunda Vinzon Estrella'
    , year: 1980
  },
  {
    title: 'Itxaro Eloise Ambulo Davao'
    , year: 2008
  },
  {
    title: 'Nora Kita Bryant Manlangit'
    , year: 1999
  },
  {
    title: 'Coraly Kennedy Baang Padilla'
    , year: 2012
  },
  {
    title: 'Aricela Eufemia Pendatun Montelibano'
    , year: 1997
  },
  {
    title: 'Callie Mckayla Tugonon Alicante'
    , year: 1986
  },
  {
    title: 'Conchetta Natalie Rasul Barrameda'
    , year: 2003
  },
  {
    title: 'Theresa Samantha Diuata Catapang'
    , year: 1984
  },
  {
    title: 'Jolie Raquel Sarip Parrilla'
    , year: 1957
  },
  {
    title: 'Modeste Yara Pichicoy Ignacio'
    , year: 1981
  },
  {
    title: 'Yashira Madalena Lanta Tamayo'
    , year: 1941
  },
  {
    title: 'Kristina Melany Diuata Miedes'
    , year: 1959
  },
  {
    title: 'Hallie Larunda Vinzon Estrella'
    , year: 1958
  },
  {
    title: 'Serenity Breonna Dura Alejandro',
    year: 1983,
  },
  {
    title: 'Luvenia Sky Dizon Tejada'
    , year: 1992
  },
  {
    title: 'Danielle Aubree Ilaban Corporal'
    , year: 1995
  },
  {
    title: 'Melosa Riya Pinagpala Santos'
    , year: 1931
  },
  {
    title: 'Nora Mayra Calim Davao'
    , year: 2000
  },
  {
    title: 'Valencia Gracie Quindipan Aldo'
    , year: 2001
  },
  {
    title: 'Christa Nicole Arai Manaloto'
    , year: 1971
  },
  {
    title: 'Roman Malcolm Tuazon Suarez'
    , year: 2007
  },
  {
    title: 'Neto Seamus Mahiya Talavér'
    , year: 1976
  },
  {
    title: 'Anastasio Noe Cosalan Domingo'
    , year: 1962
  },
  {
    title: 'Luca Andy Ricketts Bernal'
    , year: 1944
  },
  {
    title: 'Dillon Dacio Fujimori Halili',
    year: 2004,
  },
  {
    title: 'Immanuel Corey Manese Paloma'
    , year: 1984
  },
  {
    title: 'Alvin Gillermo Maruhom Fandiño'
    , year: 1962
  },
  {
    title: 'Malakai Nicanor Ala Marasigan'
    , year: 2010
  },
  {
    title: 'Logan'
    , year: 2017
  },
  {
    title: 'Full Metal Jacket'
    , year: 1987
  },
  {
    title: 'Dangal'
    , year: 2016
  },
  {
    title: 'The Sting'
    , year: 1973
  },
  {
    title: '2001: A Space Odyssey'
    , year: 1968
  },
  {
    title: "Singin' in the Rain"
    , year: 1952
  },
  {
    title: 'Toy Story'
    , year: 1995
  },
  {
    title: 'Bicycle Thieves'
    , year: 1948
  },
  {
    title: 'The Kid'
    , year: 1921
  },
  {
    title: 'Inglourious Basterds'
    , year: 2009
  },
  {
    title: 'Snatch'
    , year: 2000
  },
  {
    title: '3 Idiots'
    , year: 2009
  },
  {
    title: 'Monty Python and the Holy Grail'
    , year: 1975
  },
];

export default MasterIndexPage
