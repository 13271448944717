import React, { useState, useEffect } from "react"
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Tabs,
  Tab,
  Toolbar,
  Typography,
} from "@mui/material"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { getTransactionByRefNumber } from "../../../api/public/vortex/topup_service"
import { useParams } from "@reach/router"
import { getVortexTokenBase } from "../../../api/public/vortex/token_service"
import moment from "moment"
import CenteredProgress from "../../misc/centeredProgress"
import { getBillspaymentTransactionByRefNumber } from "../../../api/public/vortex/billspayment_service"
import { getGiftTransactionByRefNumber } from "../../../api/public/vortex/gifts_service"
import {
  getVortexTransactionByRefId,
  updateVortexByRefId,
} from "../../../api/public/vortex/transaction_db/index"
import { CopyToClipboard } from "react-copy-to-clipboard"

// Wrap the require in check for window

  // import printJS from 'print-js'
const printJS = typeof window !== `undefined` ? require("print-js") : null
const receipt = require('receipt');

receipt.config.currency = 'د.إ';
receipt.config.width = 30;
receipt.config.ruler = '-';

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  }
}

const VortexTransactionHistoryDetails = (props) => {
  let { type, refId } = useParams()

  const [isLoading, setIsLoading] = useState(false)

  const [errorData, setErrorData] = useState({
    isError: false,
    message: "",
  })

  const [copied, setCopied] = useState(false)
  const [inputsent, setInputSent] = useState(false)
  const [value, setValue] = useState(0)
  const [inputvalue, setInputValue] = useState("")
  const [inputvalueholder, setInputValueHolder] = useState("")

  const [forReceiptPrinting, setForReceiptPrinting] = useState({})

  const [transaction, setTransaction] = useState(null)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }

  const [transactionDataFromSparkleDB, setTransactionDataFromSparkleDB] =
    useState(null)

  const getTransactionByRefNumberTypeDef = async (
    access_token,
    refId,
    type
  ) => {
    switch (type) {
      case "topup":
        return await getTransactionByRefNumber(access_token, refId)
      case "billspayment":
        return await getBillspaymentTransactionByRefNumber(access_token, refId)
      case "gift":
        return await getGiftTransactionByRefNumber(access_token, refId)
      default:
        return await getTransactionByRefNumber(access_token, refId)
    }
  }

  const transactionFormBuilder = (type) => {
    switch (type) {
      case "topup":
        return (
          <Stack id="forPrinting">
            <Stack textAlign={"center"} spacing={2} >
              <Typography fontWeight={"bold"} color={"gray"}>
                TRANSACTION DETAILS
              </Typography>
              <Stack direction={"row"} justifyContent={"center"}>
                <Avatar>
                  {" "}
                  {String(transaction?.productCode).substring(0, 1)}
                </Avatar>
              </Stack>
              <Typography fontWeight={"bold"} color={"gray"}>
                {transaction?.productCode}
              </Typography>
              <Typography fontWeight={"bold"} fontSize={15}>
                {`Recepient. No. ${transaction?.recipientNumber}`}
              </Typography>
              <Typography fontWeight={"bold"} fontSize={15}>
                {`Ref. No. ${transaction?.referenceNumber}`}
              </Typography>
              <Divider />
              <Typography fontSize={10}>{transaction?.remarks}</Typography>
              <Typography fontSize={10}>

                {`${moment(
                  `${transaction?.dateCreated?.split("+")[0]}+0800`
                ).format("YYYY MMMM DD | hh:mm:ss a")} - Asia/Manila Time`}
              </Typography>
              <Typography fontSize={10}>
                {`STATUS: ${transaction?.status}`}
              </Typography>
            </Stack>
            <pre id="iAmTheReceipt" style={{ margin: 'auto' }}>
              <code >
                {`${forReceiptPrinting}`}
              </code>
            </pre>
            <div
              style={{
                display: "block",
                whiteSpace: "nowrap",
                padding: "8px 16px 16px",
              }}
            >
              <div
                style={{
                  backgroundColor: "blue",
                  color: "white",
                  borderRadius: "4px",
                  display: "inline-block",
                  textAlign: "center",
                  height: "55px",
                  position: "relative",
                  width: "100%",
                  overflow: "hidden",
                  cursor: "pointer",
                }}
                onClick={async () => {
                  console.log('print')
                  printJS('iAmTheReceipt', 'html')
                }}>
                <div style={{ margin: '0px 4vw', height: '24px', position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>
                  <span style={{ fontSize: '20px', marginTop: '-2px', lineHeight: '24px' }}>PRINT RECEIPT</span>
                </div>
              </div>
            </div>
          </Stack>
        )
      case "billspayment":
        return (
          <Stack textAlign={"center"} spacing={2} id="forPrinting">
            <Typography fontWeight={"bold"} color={"gray"}>
              TRANSACTION DETAILS
            </Typography>
            <Stack direction={"row"} justifyContent={"center"}>
              <Avatar> {String(transaction?.biller).substring(0, 1)}</Avatar>
            </Stack>
            <Typography fontWeight={"bold"} color={"gray"}>
              {transaction?.biller}
            </Typography>
            <Typography fontWeight={"bold"} fontSize={15}>
              {`Ref. No. ${transaction?.transactionNumber}`}
            </Typography>
            <Divider />
            <Typography fontSize={10}>
              {transaction?.statusDescription}
            </Typography>
            <Typography fontSize={10}>
              {/* PLDT vortex dateCreated gives +0000 - Singapore standard time */}
              {`${moment(
                `${transaction?.dateCreated?.split("+")[0]}+0800`
              ).format("YYYY MMMM DD | hh:mm:ss a")} - Asia/Manila Time`}
            </Typography>
            <Typography fontSize={10} >
              {`STATUS: ${transaction?.status}`}
            </Typography>
            <pre id="iAmTheReceipt" style={{ margin: 'auto' }}>
              <code >
                {`${forReceiptPrinting}`}
              </code>
            </pre>
            <div
              style={{
                display: "block",
                whiteSpace: "nowrap",
                padding: "8px 16px 16px",
              }}
            >
              <div
                style={{
                  backgroundColor: "blue",
                  color: "white",
                  borderRadius: "4px",
                  display: "inline-block",
                  textAlign: "center",
                  height: "55px",
                  position: "relative",
                  width: "100%",
                  overflow: "hidden",
                  cursor: "pointer",
                }}
                onClick={async () => {
                  console.log('print')
                  printJS('iAmTheReceipt', 'html')
                }}>
                <div style={{ margin: '0px 4vw', height: '24px', position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>
                  <span style={{ fontSize: '20px', marginTop: '-2px', lineHeight: '24px' }}>PRINT RECEIPT</span>
                </div>
              </div>
            </div>
          </Stack>
        )
      case "gift":
        if (transaction) {
          let FormFields = transaction?.formDetails
          let FormDetailsArray = Object.keys(transaction?.formDetails).map(
            (v) => [v, FormFields[v]]
          )

          return (
            <Stack textAlign={"center"} spacing={2} id="forPrinting">
              <Typography fontWeight={"bold"} color={"gray"}>
                TRANSACTION DETAILS
              </Typography>
              <Stack direction={"row"} justifyContent={"center"}>
                <Avatar>
                  {" "}
                  {String(transaction?.productCode).substring(0, 1)}
                </Avatar>
              </Stack>
              <Typography fontWeight={"bold"} color={"gray"}>
                {transaction?.productCode}
              </Typography>
              <Typography fontWeight={"bold"} fontSize={15}>
                {`Ref. No. ${transaction?.referenceNumber}`}
              </Typography>
              <Box mr={10} ml={10}>
                {FormDetailsArray.map((field) => {
                  return (
                    <Stack direction={"row"} justifyContent="space-between">
                      <Typography fontSize={10}>{`${field[0]}`}</Typography>
                      <Typography fontSize={10}>{`${field[1]}`}</Typography>
                    </Stack>
                  )
                })}
              </Box>
              <Divider />
              <Typography fontSize={10}>
                {`${moment(transaction?.transactionDate).format(
                  "YYYY MMMM DD | hh:mm:ss a"
                )}`}
              </Typography>
              <Typography fontSize={10}>
                {`STATUS: ${transaction?.statusDescription}`}
              </Typography>
              <pre id="iAmTheReceipt" style={{ margin: 'auto' }}>
                <code >
                  {`${forReceiptPrinting}`}
                </code>
              </pre>
              <div
                style={{
                  display: "block",
                  whiteSpace: "nowrap",
                  padding: "8px 16px 16px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "blue",
                    color: "white",
                    borderRadius: "4px",
                    display: "inline-block",
                    textAlign: "center",
                    height: "55px",
                    position: "relative",
                    width: "100%",
                    overflow: "hidden",
                    cursor: "pointer",
                  }}
                  onClick={async () => {
                    console.log('print')
                    printJS('iAmTheReceipt', 'html')
                  }}>
                  <div style={{ margin: '0px 4vw', height: '24px', position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>
                    <span style={{ fontSize: '20px', marginTop: '-2px', lineHeight: '24px' }}>PRINT RECEIPT</span>
                  </div>
                </div>
              </div>
            </Stack>
          )
        }
      default:
        return (
          <Stack textAlign={"center"} spacing={2} id="forPrinting">
            <Typography fontWeight={"bold"} color={"gray"}>
              TRANSACTION DETAILS
            </Typography>
            <Stack direction={"row"} justifyContent={"center"}>
              {/* <Avatar> {String(transaction?.productCode).substring(0, 1)}</Avatar> */}
            </Stack>
            <Typography fontWeight={"bold"} color={"gray"}>
              {transaction?.productCode}
            </Typography>
            <Typography fontWeight={"bold"} fontSize={15}>
              {`Recepient. No. ${transaction?.recipientNumber}`}
            </Typography>
            <Typography fontWeight={"bold"} fontSize={15}>
              {`Ref. No. ${transaction?.referenceNumber}`}
            </Typography>
            <Divider />
            <Typography fontSize={10}>{transaction?.remarks}</Typography>
            <Typography fontSize={10}>
              {/* PLDT vortex dateCreated gives +0000 - Singapore standard time */}
              {`${moment(
                `${transaction?.dateCreated?.split("+")[0]}+0800`
              ).format("YYYY MMMM DD | hh:mm:ss a")} - Asia/Manila Time`}
            </Typography>
            <Typography fontSize={10}>
              {`STATUS: ${transaction?.status}`}
            </Typography>
            <pre id="iAmTheReceipt" style={{ margin: 'auto' }}>
              <code >
                {`${forReceiptPrinting}`}
              </code>
            </pre>
            <div
              style={{
                display: "block",
                whiteSpace: "nowrap",
                padding: "8px 16px 16px",
              }}
            >
              <div
                style={{
                  backgroundColor: "blue",
                  color: "white",
                  borderRadius: "4px",
                  display: "inline-block",
                  textAlign: "center",
                  height: "55px",
                  position: "relative",
                  width: "100%",
                  overflow: "hidden",
                  cursor: "pointer",
                }}
                onClick={async () => {
                  console.log('print')
                  printJS('iAmTheReceipt', 'html')
                }}>
                <div style={{ margin: '0px 4vw', height: '24px', position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>
                  <span style={{ fontSize: '20px', marginTop: '-2px', lineHeight: '24px' }}>PRINT RECEIPT</span>
                </div>
              </div>
            </div>
          </Stack>
        )
    }
  }

  useEffect(async () => {
    const output = receipt.create([
      {
        type: 'text', value: [
          'BayaDirect',
          '123 STORE ST',
          'Sandbox UAE',
          'This is a work in progress receipt'
        ], align: 'center'
      },
      { type: 'empty' },
      {
        type: 'properties', lines: [
          { name: 'Order Number', value: 'XXXXXXXXXXXX' },
          { name: 'Date', value: 'XX/XX/XXXX XX:XX' }
        ]
      },
      {
        type: 'table', lines: [
          { item: 'Product 1', qty: 1, cost: 1000 },
          { item: 'Product 2 with a really long name', qty: 1, cost: 17500, discount: { type: 'absolute', value: 1000 } },
          { item: 'Another product wth quite a name', qty: 2, cost: 900 },
          { item: 'Product 4', qty: 1, cost: 80, discount: { type: 'percentage', value: 0.15 } },
          { item: 'This length is ridiculously lengthy', qty: 14, cost: 8516 },
          { item: 'Product 6', qty: 3, cost: 500 },
          { item: 'Product 7', qty: 3, cost: 500, discount: { type: 'absolute', value: 500, message: '3 for the price of 2' } }
        ]
      },
      { type: 'empty' },
      { type: 'text', value: 'Some extra information to add to the footer of this docket.', align: 'center' },
      { type: 'empty' },
      {
        type: 'properties', lines: [
          { name: 'GST (10.00%)', value: 'AED XX.XX' },
          { name: 'Total amount (excl. GST)', value: 'AED XX.XX' },
          { name: 'Total amount (incl. GST)', value: 'AED XX.XX' }
        ]
      },
      { type: 'empty' },
      {
        type: 'properties', lines: [
          { name: 'Amount Received', value: 'AED XX.XX' },
          { name: 'Amount Returned', value: 'AED XX.XX' }
        ]
      },
      { type: 'empty' },
      { type: 'text', value: 'Final bits of text at the very base of a docket. This text wraps around as well!', align: 'center', padding: 5 }
    ]);
    setForReceiptPrinting(output)
    setIsLoading(true)

    if (props?.location?.state?.paymentId === "Awaiting for GCash Payment") {
      setTransaction(JSON.parse(props.location.state.transactionData))
      setIsLoading(false)
    } else {
      let vortexTokenResponse = await getVortexTokenBase()

      if (vortexTokenResponse.status === 200) {
        let vortextTokenResult = await vortexTokenResponse.json()
        let response = await getTransactionByRefNumberTypeDef(
          vortextTokenResult.access_token,
          refId,
          type
        )

        if (response.status === 200) {
          let result = await response.json()
          setIsLoading(false)
          setTransaction(result)

          return result
        } else {
          let result = await response.json()
          setIsLoading(false)
          setErrorData({
            isError: true,
            message: result.error.message,
          })
        }
      } else {
        let vortextTokenResult = await vortexTokenResponse.json()
        setIsLoading(false)
        setErrorData({
          isError: true,
          message: vortextTokenResult.error.message,
        })
      }
    }


  }, [])

  useEffect(async () => {
    //refund process?
    if (props?.location?.state?.paymentId === "Awaiting for GCash Payment") {
    } else {
      try {
        //check if transaction has data
        if (transaction) {
          //check if transaction status is failing
          if (transaction?.status < 0 || transaction?.status === "Failed") {
            //get transaction payment id saved together with the vortex transaction
            let vortexSavedTransaction = await getVortexTransactionByRefId({
              refId:
                transaction?.referenceNumber || transaction?.transactionNumber,
            })

            //check if request is successfull
            if (vortexSavedTransaction.status === 200) {
              let vortexSavedTransactionResult =
                await vortexSavedTransaction.json()

              setTransactionDataFromSparkleDB(vortexSavedTransactionResult)

              //check if refund is already processed if not processRefund
              if (!vortexSavedTransactionResult?.paymongoRefundResourceID) {
                //process refund if status is failed
                // let savedPaymongoRefund = await processPaymongoRefund({
                //   payment_id: vortexSavedTransactionResult?.paymentId,
                //   amount: vortexSavedTransactionResult?.totalAmount,
                //   notes: "Failure of vortex request",
                //   reason: "others",
                // })

                // let updateObj = {
                //   paymongoRefundResourceID: savedPaymongoRefund._id,
                // }

                // //attach saved mongodb refund id to transaction
                // return await updateVortexByRefId({
                //   refId:
                //     transaction?.referenceNumber ||
                //     transaction?.transactionNumber,
                //   data: updateObj,
                // })
                return
              } else {
                return
              }
            } else {
              return
            }
          } else {
            return
          }
        }
      } catch (error) {
        throw Error(error)
      }
    }
  }, [transaction])



  return (
    <Box>
      <AppBar position="fixed" style={{ background: "#ffffff" }}>
        <Toolbar>
          <IconButton
            onClick={() => {
              navigate(-1)
            }}
            style={{ color: "black" }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography marginLeft={3} color={"black"}>
            Vortex transaction details
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      {isLoading && <CenteredProgress />}
      {!isLoading && !(transaction?.status >= 400 || String(transaction?.status).toLowerCase() === "failed") && (props?.location?.state?.paymentId !== "Awaiting for GCash Payment") && (
        <Card elevation={10} style={{ margin: 10 }}>
          <CardContent>
            {transactionFormBuilder(type)}{" "}
            {transactionDataFromSparkleDB?.paymongoRefundResourceID && (
              <Stack direction={"row"} justifyContent="center" mt={2}>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate(
                      `/paymongo/refund/details/${transactionDataFromSparkleDB?.paymongoRefundResourceID?.referenceNumber}`
                    )
                  }}
                >
                  VIEW REFUND
                </Button>
              </Stack>
            )}
          </CardContent>
        </Card>
      )}
      {
        !isLoading && (props?.location?.state?.paymentId === "Awaiting for GCash Payment") &&
        <Stack textAlign={"center"} spacing={2}>
          <Typography fontWeight={"bold"} color={"gray"}>
            TRANSACTION DETAILS
          </Typography>
          <Stack direction={"row"} justifyContent={"center"}>
            <Avatar>
              {" "}
              {String(transaction?.product?.name).substring(0, 1)}
            </Avatar>
          </Stack>
          <Typography fontWeight={"bold"} color={"gray"}>
            {transaction?.product?.description}
          </Typography>
          <Typography fontWeight={"bold"} fontSize={15}>
            {`Recipient. No. ${transaction?.number}`}
          </Typography>
          <Typography fontWeight={"bold"} fontSize={15}>
            {`Ref. No. ${props.location.state?.referenceNumber}`}
          </Typography>
          <Divider />
          <Typography fontSize={10}>Awaiting for payment</Typography>
          <Typography fontSize={10}>
            {`${moment(props.location.state?.createdAt).format(
              "YYYY MMMM DD | hh:mm:ss a"
            )}`}
          </Typography>
          <Typography fontSize={10}>
            {`STATUS: Cash collected - your transaction is processing`}
          </Typography>

          <div
            style={{
              display: "block",
              whiteSpace: "nowrap",
              padding: "8px 16px 16px",
            }}
          >
            <div
              style={{
                backgroundColor: "blue",
                color: "white",
                borderRadius: "4px",
                display: "inline-block",
                textAlign: "center",
                height: "55px",
                position: "relative",
                width: "100%",
                overflow: "hidden",
                cursor: "pointer",
              }}
              onClick={async () => {
                console.log('print')
              }}>
              <div style={{ margin: '0px 4vw', height: '24px', position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>
                <span style={{ fontSize: '20px', marginTop: '-2px', lineHeight: '24px' }}>PRINT RECEIPT</span>
              </div>
            </div>
          </div>
        </Stack>
      }
      {(transaction?.status >= 400 || String(transaction?.status).toLowerCase() === "failed") && (
        <Box m={3}>
          <Card elevation={10}>
            <CardContent>
              <Typography variant="h5">
                Reference Number: {props?.location?.state?.referenceNumber}
              </Typography>
              <Typography variant="h5">
                &nbsp;
              </Typography>
              <CircularProgress />
              <Typography variant="h6">
                Your order is being processed and will be fullfilled shortly .
              </Typography>
            </CardContent>
          </Card>
        </Box>
      )}
    </Box>
  )
}

export default VortexTransactionHistoryDetails
