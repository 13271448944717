import React from "react"
import { Divider } from "@material-ui/core"

const OngoingOrderProductItem = ({ product }) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px",
        }}
      >
        <div>{product.count}</div>
        <div>{product.name}</div>
        <div>{product.price * product.count}</div>
      </div>
      <Divider />
    </div>
  )
}

export default OngoingOrderProductItem
