import React from "react"
import { TextField } from "@mui/material"
import { useField } from "formik"
import MoneyInput from "@rschpdr/react-money-input";

const VortexCustomTextField = ({
  type,
  label,
  placeholder,
  inputProps,
  helperText,
  ...props
}) => {
  const [field, meta] = useField(props)
  const errorText = meta.error && meta.touched ? meta.error : ""
  const [amount, setAmount] = React.useState(0);

  function handleChange(e) {
    setAmount(e.target.value);
  }

  if(inputProps.datatype=== "money") {
    return (
    <>
      <TextField
        // currencyConfig={
        // {
        //   locale: "tl-PH",
        //   currencyCode: "PHP",
        //   currencyDisplay: "symbol",
        //   minimumFractionDigits:2,
        //   useGrouping: true
        // }}
        // customInput={TextField}
        label={label}
        variant="outlined"
        required
        fullWidth
        error={!!errorText}
        type={'number'}
        min={inputProps.minLength}
        max={inputProps.maxLength}
        inputProps={inputProps}
        helperText={`Minimum value: ${inputProps.minLength} - Format: ${helperText} - Reminder: the value here is in Peso(Php) ₱`}
        placeholder={placeholder}
        // onChange={handleChange} 
        // value={amount}
        {...field} />
    </>
    )
  } else {
    
    return (
      <TextField
        label={label}
        variant="outlined"
        required
        fullWidth
        type={type}
        placeholder={placeholder}
        {...field}
        helperText={helperText}
        error={!!errorText}
        inputProps={inputProps}
      />
    )
  }

}

export default VortexCustomTextField
