import React, { useState } from "react"
import {
  Card,
  Avatar,
  Divider,
  Typography,
  Badge,
  Box,
  CardContent,
  Stack,
  CircularProgress,
  CardActionArea,
} from "@mui/material"
import NotificationsIcon from "@material-ui/icons/Notifications"
import CampaignIcon from "@mui/icons-material/Campaign"
import CelebrationIcon from "@mui/icons-material/Celebration"
import TimeAgo from "javascript-time-ago"
import en from "javascript-time-ago/locale/en.json"
import "react-lazy-load-image-component/src/effects/blur.css"

import "../../../../assets/css/shimmer.css"
import ImageComponentWithPlaceholder from "./ImageComponentWithPlaceholder"

export const BroadcastType = Object.freeze({
  Notification: 1,
  Advisory: 2,
  Promotion: 3,
})

TimeAgo.addDefaultLocale(en)
const timeAgo = new TimeAgo("en-US")

const BroadcastCard = ({
  title = " Hey Mark, this one is picked for you",
  body = "Click to see the best deals today",
  images = [
    "https://com-sparkle.imgix.net/live/60ebffa6fcdda300175abde1.png?auto=compress&w=300",
  ],
  type = BroadcastType.Notification,
  isRead = false,
  createdAt = "2022-03-11T07:18:56.520Z",
  onClick = async () => {},
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const cardIconHandler = (broadcastType) => {
    switch (broadcastType) {
      case BroadcastType.Notification:
        return <NotificationsIcon />
      case BroadcastType.Advisory:
        return <CampaignIcon />
      case BroadcastType.Promotion:
        return <CelebrationIcon />
      default:
        return <NotificationsIcon />
    }
  }

  const cardIconBGColorHandler = (broadcastType) => {
    switch (broadcastType) {
      case BroadcastType.Notification:
        return "orange"
      case BroadcastType.Advisory:
        return "#3d9afc"
      case BroadcastType.Promotion:
        return "red"
      default:
        return "orange"
    }
  }

  return (
    <Card
      elevation={2}
      sx={{
        margin: "1em",
      }}
    >
      <CardActionArea
        onClick={async () => {
          setIsLoading(true)
          await onClick()
          setIsLoading(false)
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              variant="dot"
              invisible={isRead}
              color="error"
            >
              <Avatar sx={{ backgroundColor: cardIconBGColorHandler(type) }}>
                {cardIconHandler(type)}
              </Avatar>
            </Badge>
            <Box
              sx={{
                marginLeft: "1em",
              }}
            >
              <Typography style={{ fontSize: 10, fontWeight: "bold" }}>
                {title}
              </Typography>
              <Typography style={{ fontSize: 10 }}>
                {timeAgo.format(new Date(createdAt))}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              margin: "1em",
            }}
          >
            {images.length > 0 ? (
              <ImageComponentWithPlaceholder
                src={images[0]}
                alt="notif-image"
                height={"100px"}
                width={"100px"}
                effect="blur"
                style={{
                  borderRadius: "1em",
                  objectFit: "cover",
                }}
              />
            ) : (
              <Box></Box>
            )}
            <Typography
              style={{
                fontSize: 10,
                fontWeight: "bold",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              {body}
            </Typography>
          </Box>
          {isLoading && (
            <Stack direction={"row"} justifyContent={"center"}>
              <CircularProgress
                sx={{ color: "#ffcf10" }}
                size={40}
                thickness={4}
              />
            </Stack>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default BroadcastCard
